const isHawksoftAMS = (ams) => {
  if (ams) {
    if (ams.toString().toLowerCase() === 'hawksoft') {
      return true;
    }
  }
  return false;
};

export default isHawksoftAMS;
