import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { QuickViewDialog } from './QuickViewDialog';
import { QuickViewSkeleton } from './QuickViewSkeleton';
import { QuickViewError } from './QuickViewError';
import { useCustomerData } from './../OneView/useCustomerData';
import { QuickView, theme as covTheme } from '@aureus/cov-quick-view';
import { DataEnrichment } from './../QuickView';
import { PredictionsView } from '@aureus/donna-cov-views/';
import Paper from '@material-ui/core/Paper';
import { ThemeProvider } from '@material-ui/core/styles';
import managerConfig from './../OneView/ManagerConfig.json';
import useActivities from './../Activities/useActivities';
import sendAction from '../Action/sendAction';
import { contextProperties } from '../Diem/ContextProperties';
import getQuote from '../Quote/getQuote';
import sendQuote from '../Quote/sendQuote';
// import isQuoteServiceAllowed from '../Quote/isQuoteServiceAllowed';
// import isQuoteEnabled from '../Quote/isQuoteEnabled';
import getUserEmail from '../Quote/getUserEmail';
import { quoteTypes } from '../Quote/quoteTypes';
import {
  getTitle,
  getCustomerArr,
  getCustomerID,
  setPrevNextDisabledValue,
} from './Navigation';
import showQuote from '../Quote/showQuote';
import { UserContext } from '../UserContext';
import getLeadHistory from '../AgencyZoom/getLeadHistory';
import createLead from '../AgencyZoom/createLead';
import agencyZoomLogin from '../AgencyZoom/login';
// import getLeadCreationDetails from '../AgencyZoom/getLeadCreationDetails';
// import isAgencyZoomEnabled from '../AgencyZoom/isAgencyZoomEnabled';
// import isAgencyZoomServiceAllowed from '../AgencyZoom/isAgencyZoomAllowed';
import createInsuredLead from '../InsuredMine/createInsuredLead';
import isInsuredMineEnabled from '../InsuredMine/isInsuredMineEnabled';
import { isAgencyZoomAllowed, isQuoteAllowed } from '../../utils/donnaPlansUtil';
import isHawksoftAMS from '../../utils/isHawksoftAMS';

const useStyles = makeStyles((theme) => ({
  covLeftPadding: {
    '& .oneview_main_div': {
      paddingLeft: theme.spacing(10),
      backgroundImage: 'none',
      backgroundColor: '#2d6089',
    },
    '& .oneview_nav_tab_div': {
      backgroundColor: '#2d6089 !important',
    },
    '& .oneview_nav_active_tab_div': {
      backgroundColor: '#fff !important',
      color: '#000 !important',
    },
  },
  paperRoot: {
    boxShadow: 'none',
  },
}));

export function QuickViewModal(props) {
  const classes = useStyles();
  const { user } = useContext(UserContext);
  const userID = user.getTenantLevelUserId();
  const tenant = user.getTenant();
  const tenantApi = user.getTenantApi();
  const planType = tenantApi.getPlanType();
  const amsName = tenantApi.getAmsName();
  
  const isHawksoftAMSAllowed = isHawksoftAMS(amsName);

  const { customerId, context } = useParams();
  const {
    backPath,
    modalTitle,
    customers,
    customerIdsWithContext,
    prefixContext,
    customerCount,
  } = props;
  const customerData = useCustomerData({
    customerId,
  });
  let addContext = '';
  if (prefixContext) {
    addContext = `${context}/`;
  }
  const { fetching, success, error, data } = customerData;
  const history = useHistory();
  const helpVideoPlaylist = process.env.REACT_APP_LEAD_HELP_VIDEOS_LINK;
  let selected = [];
  if (context && contextProperties[context]) {
    selected = contextProperties[context].selected;
  }
  const goBack = () => {
    history.push(`${backPath}/${context}`);
  };
  const expand = (custId, obj) => {
    let selectedView = obj.view;
    if (
      obj.view === 'CustomerCompactView' ||
      obj.view === 'DataENRCompactView'
    ) {
      selectedView = 'Customer';
    }
    if (context) {
      history.push(
        `${backPath}/${addContext}oneview/${customerId}/${selectedView}`
      );
    } else {
      history.push(`${backPath}/oneview/${customerId}/`);
    }
  };
  const handleClick = (custId) => {
    if (context) {
      history.push(`${backPath}/${addContext}quickview/${custId}/${context}`);
    } else {
      history.push(`${backPath}/quickview/${custId}`);
    }
  };

  const title = getTitle(modalTitle, context, contextProperties);

  const customersArr = getCustomerArr(customerIdsWithContext, customers);
  const customerID = getCustomerID(customerIdsWithContext, customers);

  const { isDisableNext, isDisablePrev, currentIndex } =
    setPrevNextDisabledValue(customersArr, customerID, customerId);

  const getNextCustomer = () => {
    let custId = customersArr[currentIndex + 1][customerID];
    handleClick(custId);
  };

  const getPrevCustomer = () => {
    let custId = customersArr[currentIndex - 1][customerID];
    handleClick(custId);
  };

  const addAccountApiPath = '/api/outbound/v1/addAccount';
  const getAccountsApiPath = '/api/outbound/v1/getAccounts';
  const createChannelApiPath = '/api/outbound/v1/createChannel';
  const getChannelsApiPath = '/api/outbound/v1/getChannels';
  const updateChannelApiPath = '/api/outbound/v1/editChannel';
  const sendToChannelsApiPath = '/api/outbound/v1/sendToChannels';
  const getLeadCreationDetailsPath = '/api/outbound/v1/getLeadCreationDetails';
  const getTreatmentDetailsPath = '/api/outbound/v1/getTreatmentDetails';

  const featureLockConfig = {
    lockTitle: 'Upgrade plan to avail this feature',
    lockWidget:
      planType && (planType === 'solo' || planType === 'free') ? true : false,
    redirectURL: '/home/settings/billing-details/plans',
  };

  const isAgencyZoomServiceAllowed = () => {
    const agencyZoomAllowed = isAgencyZoomAllowed(planType);
    return {
      error: false,
      data: {
        agencyZoomAllowed,
      },
    };
  };

  const isAgencyZoomEnabled = () => {
    const agencyZoomEnabled = tenantApi.getAgencyZoomEnabled();
    return {
      error: false,
      data: {
        agencyZoomEnabled,
      },
    };
  };

  const isQuoteServiceAllowed = () => {
    const quoteAllowed = isQuoteAllowed(planType);
    return {
      error: false,
      data: {
        quoteAllowed,
      },
    };
  };

  const isQuoteEnabled = () => {
    const quoteEnabled = tenantApi.getQuoteEnabled();
    return {
      error: false,
      data: {
        quoteEnabled,
      },
    };
  };

  return (
    <div>
      <QuickViewDialog
        open={true}
        handleClose={goBack}
        title={title}
        customersArr={customersArr}
        customerID={customerID}
        isDisableNext={isDisableNext}
        isDisablePrev={isDisablePrev}
        getNextCustomer={getNextCustomer}
        getPrevCustomer={getPrevCustomer}
        customerData={customerData}
        customerCount={customerCount}
      >
        {fetching && <QuickViewSkeleton />}
        {success && (
          <>
            <div className={classes.covLeftPadding}>
              <Paper classes={{ root: classes.paperRoot }}>
                <ThemeProvider theme={covTheme}>
                  <QuickView
                    manageConfig={managerConfig}
                    custCOV={{ aureus: { data } }}
                    selected={selected}
                    goToCovFunction={(custId, obj) => {
                      expand(custId, obj);
                    }}
                    quoteType={quoteTypes.RELATIONSHIP}
                    components={{
                      DataENRCompactView: DataEnrichment,
                      Prediction: (props) => (
                        <PredictionsView
                          tenant={tenant}
                          userID={userID}
                          addLead={createLead}
                          getLeadHistory={getLeadHistory}
                          leadLogin={agencyZoomLogin}
                          isAgencyZoomServiceAllowed={
                            isAgencyZoomServiceAllowed
                          }
                          isAgencyZoomEnabled={isAgencyZoomEnabled}
                          useActivities={useActivities}
                          sendAction={sendAction}
                          customerId={customerId}
                          getQuote={getQuote}
                          sendQuote={sendQuote}
                          isQuoteServiceAllowed={isQuoteServiceAllowed}
                          isQuoteEnabled={isQuoteEnabled}
                          getUserEmail={getUserEmail}
                          quoteType={quoteTypes.ACTIVITY}
                          showQuote={showQuote}
                          helpPlaylist={helpVideoPlaylist}
                          isInsuredMineEnabled={isInsuredMineEnabled}
                          addInsuredLead={createInsuredLead}
                          addAccountApiPath={addAccountApiPath}
                          getAccountsApiPath={getAccountsApiPath}
                          createChannelApiPath={createChannelApiPath}
                          getChannelsApiPath={getChannelsApiPath}
                          updateChannelApiPath={updateChannelApiPath}
                          sendToChannelsApiPath={sendToChannelsApiPath}
                          getLeadCreationDetailsPath={getLeadCreationDetailsPath}
                          getTreatmentDetailsPath={getTreatmentDetailsPath}
                          featureLockConfig={featureLockConfig}
                          isHawksoftAMSAllowed={isHawksoftAMSAllowed}
                          {...props}
                        />
                      ),
                    }}
                    noRouter={false}
                    pathname="/"
                  />
                </ThemeProvider>
              </Paper>
            </div>
          </>
        )}
        {error && <QuickViewError customerId={customerId} />}
      </QuickViewDialog>
    </div>
  );
}

QuickViewModal.propTypes = {
  backPath: PropTypes.string.isRequired,
};
