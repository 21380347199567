import React, { useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';
import { Portal, Snackbar, makeStyles } from '@material-ui/core';
import {
  dialogConfig,
  dialogType,
} from '@aureus/outbound-channels/dist/config/dialogConfig';
import { ChannelDialog, CreateChannelManager } from '@aureus/outbound-channels';

const useStyles = makeStyles(() => ({
  message: {
    fontSize: 20,
  },
  icon: {
    fontSize: 32,
  },
}));

const OutboundChannelManager = (props) => {
  const classes = useStyles();
  const {
    tenant,
    userID,
    providerNameInSettings,
    dialogKey,
    addAccountApiPath,
    getAccountsApiPath,
    createChannelApiPath,
    getChannelsApiPath,
    updateChannelApiPath,
    sendToChannelsApiPath,
    getLeadCreationDetails,
    getLeadCreationDetailsPath,
    getTreatmentDetailsPath,
    setTriggerChannelFetch,
    featureLockConfig,
    incrementChannelChangeCount,
    isHawksoftAMSAllowed
  } = props;

  const [openDialog, setOpenDialog] = useState(false);
  const [modalConfig, setModalConfig] = useState({});
  const [leadSnackbarOpen, setLeadSnackbarOpen] = useState(false);
  const [editChannelDetails, setEditChannelDetails] = useState(null);

  const [alertObject, setAlertObject] = useState({
    type: '',
    message: '',
  });

  const handleClose = () => {
    setModalConfig({});
    setEditChannelDetails(null);
    setOpenDialog(false);
  };

  const handleOpen = (key) => {
    setModalConfig(dialogConfig[key]);
    setOpenDialog(true);
  };

  const leadSnackbarClose = () => {
    setLeadSnackbarOpen(false);
  };

  const getLeadCreationDetailsFailed = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message:
        'Something went wrong while getting lead details, please try again',
    });
  };

  const channelCreationSuccess = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'success',
      message: 'Channel Created Successfully',
    });
    // setTriggerChannelFetch(true);
    incrementChannelChangeCount();
  };

  const channelCreationFailed = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message: 'Failed to create channel. Please try again after sometime',
    });
  };

  const channelUpdationSuccess = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'success',
      message: 'Channel Updated Successfully',
    });
    setTriggerChannelFetch(true);
  };

  const channelUpdationFailed = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message: 'Failed to updated channel. Please try again after sometime',
    });
  };

  const addAccountSuccess = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'success',
      message: 'Account Details Added Successfully',
    });
  };

  const sendNowFailure = () => {
    setLeadSnackbarOpen(true);
    setAlertObject({
      type: 'error',
      message:
        'Failed to send to selected channels. Please try again after sometime',
    });
  };

  return (
    <>
      <CreateChannelManager
        dialogKey={dialogKey}
        handleOpen={handleOpen}
        redirectTo={dialogType.CREATE_CHANNEL_INTEGRATION}
        getLeadCreationDetails={getLeadCreationDetails}
      />
      {openDialog && (
        <ChannelDialog
          handleClose={handleClose}
          handleOpen={handleOpen}
          modalConfig={modalConfig}
          tenant={tenant}
          userID={userID}
          providerNameInSettings={providerNameInSettings}
          addAccountApiPath={addAccountApiPath}
          getAccountsApiPath={getAccountsApiPath}
          createChannelApiPath={createChannelApiPath}
          getChannelsApiPath={getChannelsApiPath}
          updateChannelApiPath={updateChannelApiPath}
          sendToChannelsApiPath={sendToChannelsApiPath}
          getLeadCreationDetails={getLeadCreationDetails}
          getLeadCreationDetailsFailed={getLeadCreationDetailsFailed}
          channelCreationSuccess={channelCreationSuccess}
          channelCreationFailed={channelCreationFailed}
          addAccountSuccess={addAccountSuccess}
          editChannelDetails={editChannelDetails}
          setEditChannelDetails={setEditChannelDetails}
          getLeadCreationDetailsPath={getLeadCreationDetailsPath}
          getTreatmentDetailsPath={getTreatmentDetailsPath}
          sendNowFailure={sendNowFailure}
          featureLockConfig={featureLockConfig}
          isHawksoftAMSAllowed={isHawksoftAMSAllowed}
        />
      )}
      {leadSnackbarOpen &&
        alertObject !== null &&
        alertObject.type !== '' &&
        alertObject.message !== '' && (
          <Portal>
            <Snackbar
              open={leadSnackbarOpen}
              // autoHideDuration={5000}
              onClose={leadSnackbarClose}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
              <MuiAlert
                severity={alertObject.type}
                elevation={6}
                variant="filled"
                onClose={leadSnackbarClose}
                classes={{
                  message: classes.message,
                  icon: classes.icon,
                }}
              >
                {alertObject.message}
              </MuiAlert>
            </Snackbar>
          </Portal>
        )}
    </>
  );
};

export default OutboundChannelManager;
